import React from 'react';
import {Cell, Grid, Row} from '@material/react-layout-grid';
import {message} from '../../utils/message'
import { Api } from '../../api'


export class TapdTencentComponent extends React.Component {
    constructor(props) {
        super(props);
        this.do = this.do.bind(this);
    }

    componentDidMount() {
        this.do()
    }

    do() {
        new Api(this.props.rootState).get(Api.Action.API_BRIDGE_TAPD_TENCENT, {

        }).then((response) => {
            console.log("check token, resp:", response.data);
            message.showError("请求成功: " + response.data);
        }).catch((e) => {
            message.showError("请求失败, 请联系 「腾讯公共服务」 成员协助解决, " + e);
        });
    }

    render() {
        return (
            <div className='Ticket'>
                <Grid className="Ticket-grid">
                    <Row>
                        <Cell columns={2}/>

                        <Cell columns={8}>
                            <h1>TAPD bridge 测试</h1>
                        </Cell>
                        <Cell columns={2}/>
                    </Row>
                </Grid>
            </div>
        )
    }
}

import React from "react";
import {Cell, Grid, Row} from '@material/react-layout-grid';
import {Api} from "../../api";
import {message} from "../../utils/message";
import {getUrlParams} from '../../utils/xss'


export class JoinGroupComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            ok: false
        }
    }

    componentDidMount(){
        let urlParams = new URLSearchParams(window.location.search);
        let id = getUrlParams(urlParams, 'id');
        new Api(null).get(Api.Action.API_GROUP_JOIN_GROUP,{
            id: id
        }).then((response) => {
            console.log("JoinGroup response:", response);
            if(response.status !== 200 || response.data.code !== 10000){
                message.showError("JoinGroup error: ", response.data.msg);
                let massage = "加入用户组错误，请重新生成链接。。。";
                if(response.data.code===11004){
                    massage = "链接已过期。请重新生成链接。。。";
                }
                this.setState({ok: false, isLoading: false, message: massage})
            } else {
                this.setState({ok: true, isLoading: false})
            }
        }).catch((e) => {
            console.log("JoinGroup, error", e);
            message.showError("加入用户组失败:", e)
        });
    }

    render() {
        return(
            <div className='JoinGroup'>
                <Grid className="JoinGroup-grid">
                    <Row>
                        <Cell columns={2}/>

                        <Cell columns={8}>
                            <h1>加入用户组</h1>
                            <hr/>

                            {
                                this.state.isLoading && (
                                    <p> 正在加入用户组 ... </p>
                                )
                            }
                            {
                                !this.state.ok && !this.state.isLoading && this.state.message && (
                                    <p> {this.state.message} </p>
                                )
                            }

                            {
                                this.state.ok && (
                                    <p> 加入用户组成功 :) </p>
                                )
                            }

                        </Cell>

                        <Cell columns={2}/>
                    </Row>
                </Grid>
            </div>
        )

    }
}
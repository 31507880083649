import { connect } from 'react-redux'
import {TargetDetailComponent} from "../../components/permission/TargetDetail";

const mapStateToProps = state => {
    return {
        ...state.permission,
    }
};

export const TargetDetail = connect(
    mapStateToProps
)(TargetDetailComponent);

export default TargetDetail;
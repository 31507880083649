import React from 'react';
import Modal from 'react-modal';
import Select from '@material/react-select';
import Card, {
    CardPrimaryContent,
    CardActions,
    CardActionButtons,
} from "@material/react-card";

export class AddAccessComponent extends React.Component {

    constructor(props){
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.addAccess = this.addAccess.bind(this);
        this.state = {
            targetId: "",
            accessLevel: 0
        }
    }

    closeModal() {
        this.props.storeState({accessModalIsOpen: false});
    }

    addAccess() {
        // setState是异步的，所以groupName如果从state中获得是错误的
        let accessInfo = {
            groupId: this.props.selectedGroup.groupId,
            targetId: this.state.targetId,
            accessLevel: this.state.accessLevel,
        };
        console.log("addAccess, info:", accessInfo);
        this.props.addAccess(accessInfo);

        this.closeModal();
    }

    componentDidMount() {
        this.props.fetchTargets();
    }

    render() {
        let {targets} = this.props;
        let targetIdOptions = targets ? targets.map(target => ({label: target.target_id, value: target.target_id})) : [];
        return (
            <div>
                <Modal
                    isOpen={this.props.accessModalIsOpen}
                    onRequestClose={this.closeModal}
                    className="AddGroup-Modal"
                    contentLabel="Add Group Modal"
                    appElement={document.getElementById('root')}
                >
                    <div>
                        <Card>
                            <CardPrimaryContent>
                                <h3 style={{textAlign: "center"}}>添加资源权限</h3>

                                <p style={{textAlign: "center", color: "rgb(128,128,128)"}}>功能未完善, 此页面仅供 TPS 内部使用</p>
                            </CardPrimaryContent>

                            <CardActions>

                                <div style={{width: "80%", margin: "auto"}}>
                                    <Select
                                        className="AddAccess-Select"
                                        label='Target'
                                        value={this.state.targetId}
                                        onChange={(evt) => this.setState({targetId: evt.target.value})}
                                        options={targetIdOptions}
                                    />
                                    <Select
                                        className="AddAccess-Select"
                                        label='Level'
                                        value={this.state.accessLevel.toString()}
                                        onChange={(evt) => this.setState({accessLevel: parseInt(evt.target.value, 10)})}
                                        options={Array.from(Array(16), (v,k) =>(k+1).toString())}
                                    />
                                    <div style={{width: "100%", marginTop:"20px"}}>
                                        <div style={{float: "left"}}>
                                            <CardActionButtons>
                                                <button className="mdc-button mdc-button--raised" onClick={this.addAccess}>
                                                    <span className="mdc-button__label">确定</span>
                                                </button>
                                            </CardActionButtons>
                                        </div>
                                        <div style={{float: "right"}}>
                                            <CardActionButtons>
                                                <button className="mdc-button mdc-button--raised" onClick={this.closeModal}>
                                                    <span className="mdc-button__label">取消</span>
                                                </button>
                                            </CardActionButtons>
                                        </div>

                                    </div>
                                </div>

                            </CardActions>
                        </Card>
                    </div>
                </Modal>
            </div>
        );
    }
}
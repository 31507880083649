import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import AddAccess from "../../containers/permission/AddAccessModal";
import Copy from "copy-to-clipboard";

export class GroupDetailComponent extends React.Component {

    constructor(props) {
        super(props);
        this.onOwnerDelete = this.onOwnerDelete.bind(this);
        this.onMemberDelete = this.onMemberDelete.bind(this);

        this.state = {
            isOpen: false,
            isADLOpen: false,
            ownerId: "",
            memberId: "",
            targetId: "",
        };
    }

    componentDidMount() {
        if(this.props.groupId){
            this.props.changeGroup(this.props.groupId)
        }
    }

    handleDelete = () => {
        if(this.state.ownerId){
            this.props.deleteOwner(this.props.groupId,this.state.ownerId);
        }
        if(this.state.memberId){
            this.props.deleteMember(this.props.groupId,this.state.memberId);
        }
        if(this.state.targetId){
            this.props.changeGroupAccess(this.props.groupId,this.state.targetId, 0);
        }
        this.setState({
            isOpen: false,
            ownerId: "",
            memberId: "",
            targetId: "",
        });
    };


    handleClose = () => {
        this.setState({ isOpen: false });
    };

    handleCloseADL = () => {
        this.setState({ isADLOpen: false });
        this.props.storeSelectGroupState({
            joinLink: ""
        });
    };

    handleCopy = () => {
        Copy(this.props.joinLink);
        this.handleCloseADL();
    };

    createAddGroupLink = (roleType) => {
        this.props.createGroupLink(this.props.groupId, roleType);
        this.setState({ isADLOpen: true });
    };

    render() {
        console.log("GroupListComponent, render props", this.props);
        let { owners, members, accesses } = this.props ? this.props : [];
        return (
            <React.Fragment>
                <div className="GroupDetail">
                    <AddAccess/>
                    <Dialog
                        open={this.state.isOpen}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"提示"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                确定删除？
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleDelete} color="primary">
                                确定
                            </Button>
                            <Button onClick={this.handleClose} color="primary" autoFocus>
                                取消
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.isADLOpen}
                        onClose={this.handleCloseADL}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"分享"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                请复制以下链接分享给需要添加到用户组的用户 (24 小时后过期) <br/>
                                该用户便会获得用户组所拥有的权限 <br/>
                                <code className="GroupDetail-jon-link">
                                    {this.props.joinLink}
                                </code>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCopy} color="primary" autoFocus>
                                复制
                            </Button>
                            <Button onClick={this.handleCloseADL} color="primary" autoFocus>
                                关闭
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {
                        owners ? (
                            <div className="GroupDetailOwner GroupDetailPerson">
                                <h4>组管理员：
                                    {/*<button className="mdc-button">*/}
                                        {/*<i className="material-icons mdc-button__icon" aria-hidden="true">add_circle_outline</i>*/}
                                    {/*</button>*/}
                                    <button className="mdc-button" onClick={(e) => this.createAddGroupLink(1)}>
                                        <i className="material-icons mdc-button__icon" aria-hidden="true">add_circle</i>
                                    </button>
                                </h4>

                                <ul className="mdc-list">
                                    <li className="mdc-list-item">
                                        <div className="mdc-layout-grid mdc-layout-grid--align-left GroupDetail-grid">
                                            <div className="mdc-layout-grid__inner GroupDetail-inner">
                                                <div className="mdc-layout-grid__cell--span-1 GroupDetail-cell">
                                                    <span className="mdc-list-item__text"><b>ID</b></span>
                                                </div>
                                                <div className="mdc-layout-grid__cell--span-5 GroupDetail-cell">
                                                    <span className="mdc-list-item__text"><b>用户ID</b></span>
                                                </div>
                                                <div className="mdc-layout-grid__cell--span-3 GroupDetail-cell">
                                                    <span className="mdc-list-item__text"><b>昵称</b></span>
                                                </div>
                                                {/*<div className="mdc-layout-grid__cell--span-2 GroupDetail-cell">*/}
                                                    {/*<span className="mdc-list-item__text"><b>更换角色</b></span>*/}
                                                {/*</div>*/}
                                                <div className="mdc-layout-grid__cell--span-3 GroupDetail-cell">
                                                    <span className="mdc-list-item__text"><b>是否删除</b></span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {
                                        owners && owners.map((owner, index) => {
                                            return (
                                                <li className="mdc-list-item" key={index}>
                                                    <div className="mdc-layout-grid mdc-layout-grid--align-left GroupDetail-grid">
                                                        <div className="mdc-layout-grid__inner GroupDetail-inner">
                                                            <div className="mdc-layout-grid__cell--span-1 GroupDetail-cell">
                                                                <span className="mdc-list-item__text">{index + 1}</span>
                                                            </div>
                                                            <div className="mdc-layout-grid__cell--span-5 GroupDetail-cell">
                                                                <span className="mdc-list-item__text">{owner.user_id}</span>
                                                            </div>
                                                            <div className="mdc-layout-grid__cell--span-3 GroupDetail-cell">
                                                                <span className="mdc-list-item__text"><b>{owner.nick_name}</b></span>
                                                            </div>
                                                            {/*<div className="mdc-layout-grid__cell--span-2 GroupDetail-cell">*/}
                                                                {/*<span className="mdc-list-item__text">*/}
                                                                    {/*<i className="material-icons mdc-chip__icon mdc-chip__icon--leading">vertical_align_bottom</i>*/}
                                                                {/*</span>*/}
                                                            {/*</div>*/}
                                                            <div className="mdc-layout-grid__cell--span-3 GroupDetail-cell">
                                                                <span className="mdc-list-item__text" onClick={(e) => this.onOwnerDelete(owner.user_id, e)}>
                                                                     <i className="material-icons mdc-chip__icon mdc-chip__icon--leading PersonDelete">clear</i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        ):(<br/>)
                    }
                    {
                        members ? (
                            <div className="GroupDetailMember GroupDetailPerson">
                                <h4>普通成员：
                                    {/*<button className="mdc-button">*/}
                                        {/*<i className="material-icons mdc-button__icon" aria-hidden="true">add_circle_outline</i>*/}
                                    {/*</button>*/}
                                    <button className="mdc-button" onClick={(e) => this.createAddGroupLink(0)}>
                                        <i className="material-icons mdc-button__icon" aria-hidden="true">add_circle</i>
                                    </button>
                                </h4>
                                <ul className="mdc-list">
                                    <li className="mdc-list-item">
                                        <div className="mdc-layout-grid mdc-layout-grid--align-left GroupDetail-grid">
                                            <div className="mdc-layout-grid__inner GroupDetail-inner">
                                                <div className="mdc-layout-grid__cell--span-1 GroupDetail-cell">
                                                    <span className="mdc-list-item__text"><b>ID</b></span>
                                                </div>
                                                <div className="mdc-layout-grid__cell--span-5 GroupDetail-cell">
                                                    <span className="mdc-list-item__text"><b>用户ID</b></span>
                                                </div>
                                                <div className="mdc-layout-grid__cell--span-3 GroupDetail-cell">
                                                    <span className="mdc-list-item__text"><b>昵称</b></span>
                                                </div>
                                                {/*<div className="mdc-layout-grid__cell--span-2 GroupDetail-cell">*/}
                                                    {/*<span className="mdc-list-item__text"><b>更换角色</b></span>*/}
                                                {/*</div>*/}
                                                <div className="mdc-layout-grid__cell--span-3 GroupDetail-cell">
                                                    <span className="mdc-list-item__text"><b>是否删除</b></span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {
                                        members && members.map((member, index) => {
                                            return (
                                                <li className="mdc-list-item" key={index}>
                                                    <div className="mdc-layout-grid mdc-layout-grid--align-left GroupDetail-grid">
                                                        <div className="mdc-layout-grid__inner GroupDetail-inner">
                                                            <div className="mdc-layout-grid__cell--span-1 GroupDetail-cell">
                                                                <span className="mdc-list-item__text">{index + 1}</span>
                                                            </div>
                                                            <div className="mdc-layout-grid__cell--span-5 GroupDetail-cell">
                                                                <span className="mdc-list-item__text">{member.user_id}</span>
                                                            </div>
                                                            <div className="mdc-layout-grid__cell--span-3 GroupDetail-cell">
                                                                <span className="mdc-list-item__text"><b>{member.nick_name}</b></span>
                                                            </div>
                                                            {/*<div className="mdc-layout-grid__cell--span-2 GroupDetail-cell">*/}
                                                                {/*<span className="mdc-list-item__text">*/}
                                                                    {/*<i className="material-icons mdc-chip__icon mdc-chip__icon--leading">vertical_align_top</i>*/}
                                                                {/*</span>*/}
                                                            {/*</div>*/}
                                                            <div className="mdc-layout-grid__cell--span-3 GroupDetail-cell">
                                                                <span className="mdc-list-item__text" onClick={(e) => this.onMemberDelete(member.user_id, e)}>
                                                                    <i className="material-icons mdc-chip__icon mdc-chip__icon--leading PersonDelete">clear</i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        ):(<br/>)
                    }
                    {
                        accesses ? (
                            <div className="GroupDetailAccess">
                                <h4>权限详情：
                                    <button className="mdc-button" onClick={(e) => this.props.storeState({
                                        accessModalIsOpen: true,
                                    })}>
                                        <i className="material-icons mdc-button__icon" aria-hidden="true">add_circle</i>
                                    </button>
                                </h4>
                                <ul className="mdc-list">
                                    <li className="mdc-list-item">
                                        <div className="mdc-layout-grid mdc-layout-grid--align-left GroupDetail-grid">
                                            <div className="mdc-layout-grid__inner GroupDetail-inner">
                                                <div className="mdc-layout-grid__cell--span-1 GroupDetail-cell">
                                                    <span className="mdc-list-item__text"><b>ID</b></span>
                                                </div>
                                                <div className="mdc-layout-grid__cell--span-3 GroupDetail-cell">
                                                    <span className="mdc-list-item__text"><b>平台</b></span>
                                                </div>
                                                <div className="mdc-layout-grid__cell--span-3 GroupDetail-cell">
                                                    <span className="mdc-list-item__text"><b>资源</b></span>
                                                </div>
                                                <div className="mdc-layout-grid__cell--span-2 GroupDetail-cell">
                                                    <span className="mdc-list-item__text"><b>权限等级</b></span>
                                                </div>
                                                <div className="mdc-layout-grid__cell--span-3 GroupDetail-cell">
                                                    <span className="mdc-list-item__text"><b>是否删除</b></span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {
                                        accesses && accesses.map((access, index) => {
                                            let target = access.target_id.split("::");
                                            return (
                                                <li className="mdc-list-item" key={index}>
                                                    <div className="mdc-layout-grid mdc-layout-grid--align-left GroupDetail-grid">
                                                        <div className="mdc-layout-grid__inner GroupDetail-inner">
                                                            <div className="mdc-layout-grid__cell--span-1 GroupDetail-cell">
                                                                <span className="mdc-list-item__text">{index + 1}</span>
                                                            </div>
                                                            <div className="mdc-layout-grid__cell--span-3 GroupDetail-cell">
                                                                <span className="mdc-list-item__text">{target[0]}</span>
                                                            </div>
                                                            <div className="mdc-layout-grid__cell--span-3 GroupDetail-cell">
                                                                <span className="mdc-list-item__text">{target[1]}</span>
                                                            </div>
                                                            <div className="mdc-layout-grid__cell--span-2 GroupDetail-cell">
                                                                <span className="mdc-list-item__text">{access.access_level}</span>
                                                            </div>
                                                            <div className="mdc-layout-grid__cell--span-3 GroupDetail-cell">
                                                                <span className="mdc-list-item__text">
                                                                    <i className="material-icons mdc-chip__icon mdc-chip__icon--leading PersonDelete" onClick={(e) => this.onAccessDelete(access.target_id, e)}>clear</i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        ):(<br/>)
                    }
                </div>
            </React.Fragment>
        );
    }

    onOwnerDelete(ownerId, e){
        this.setState({
            isOpen: true,
            ownerId: ownerId,
        });
    }

    onMemberDelete(memberId, e){
        this.setState({
            isOpen: true,
            memberId: memberId,
        });
    }

    onAccessDelete(targetId, e){
        this.setState({
            isOpen: true,
            targetId: targetId,
        });
    }
}
import { connect } from 'react-redux'

import {mergeState} from '../../actions/foundation';
import {LayoutTinyComponent} from '../../components/foundation/LayoutTiny';

const mapStateToProps = state => {
    return {
        ...state.meta,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        storeState: (state) => {
            dispatch(mergeState(state))
        },
    }
};

export const LayoutTiny = connect(
    mapStateToProps,
    mapDispatchToProps
)(LayoutTinyComponent);

export default LayoutTiny;

import { connect } from 'react-redux'

import { TicketComponent } from '../../components/auth/Ticket';

const mapStateToProps = state => {
    return {
        rootState: state,
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export const Ticket = connect(
    mapStateToProps,
    mapDispatchToProps
)(TicketComponent);

export default Ticket;
import React from 'react';
import Modal from 'react-modal';

import Card, {
    CardPrimaryContent,
    CardActions,
    CardActionButtons,
} from "@material/react-card";

import ChipInput from 'material-ui-chip-input';

import { MDCTextField } from '@material/textfield';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

export class AddGroupComponent extends React.Component {

    constructor(props){
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.addGroup = this.addGroup.bind(this);
        this.input = null;
        this.state = {
            groupOwners: [],
            groupMembers: [],
        };
    }

    closeModal() {
        this.props.storeState({modalIsOpen: false});
    }

    addGroup() {
        const textField = new MDCTextField(document.querySelector('.mdc-text-field'));
        let groupName = textField.getDefaultFoundation().getValue();
        // setState是异步的，所以groupName如果从state中获得是错误的
        let groupInfo = {
            groupName: groupName,
            groupOwners: this.state.groupOwners,
            groupMembers: this.state.groupMembers,
        };
        console.log("addGroup, info:", groupInfo);
        this.props.addGroup(groupInfo);

        // 重置state
        this.setState({
            groupOwners: [],
            groupMembers: [],
        });
        this.closeModal();
    }

    onChangeGroupOwners = groupOwners => {
        this.setState({groupOwners: groupOwners});
    };

    onChangeGroupMembers = groupMembers => {
        this.setState({groupMembers: groupMembers});
    };

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.modalIsOpen}
                    onRequestClose={this.closeModal}
                    className="AddGroup-Modal"
                    contentLabel="Add Group Modal"
                    appElement={document.getElementById('root')}
                >
                    <div>
                        <Card>
                            <CardPrimaryContent>
                                <h3 style={{textAlign:"center"}}>添加用户组</h3>
                            </CardPrimaryContent>

                            <CardActions>

                                <div style={{width: "80%", margin: "auto"}}>
                                    <div style={{width: "100%" }}>
                                        <div style={{paddingTop: '10px'}}>
                                            <div className="mdc-text-field mdc-text-field--fullwidth">
                                                <input className="mdc-text-field__input"
                                                       type="text"
                                                       placeholder="用户组名"
                                                       aria-label="user_group_name" />
                                            </div>
                                        </div>
                                        <div style={{paddingTop: '10px'}}>
                                            <MuiThemeProvider>
                                            <ChipInput
                                                placeholder="管理员"
                                                onChange={(groupOwners) => this.onChangeGroupOwners(groupOwners)}
                                                fullWidth={true}
                                            />
                                            </MuiThemeProvider>
                                        </div>
                                        <div style={{paddingTop: '10px'}}>
                                            <MuiThemeProvider>
                                            <ChipInput
                                                placeholder="普通成员"
                                                onChange={(groupMembers) => this.onChangeGroupMembers(groupMembers)}
                                                fullWidth={true}
                                            />
                                            </MuiThemeProvider>
                                        </div>
                                    </div>
                                    <br/><br/>
                                    <div style={{width: "100%"}}>
                                        <div style={{float: "left"}}>
                                            <CardActionButtons>
                                                <button className="mdc-button mdc-button--raised" onClick={this.addGroup}>
                                                    <span className="mdc-button__label">确定</span>
                                                </button>
                                            </CardActionButtons>
                                        </div>
                                        <div style={{float: "right"}}>
                                            <CardActionButtons>
                                                <button className="mdc-button mdc-button--raised" onClick={this.closeModal}>
                                                    <span className="mdc-button__label">取消</span>
                                                </button>
                                            </CardActionButtons>
                                        </div>

                                    </div>
                                </div>

                            </CardActions>
                        </Card>
                    </div>
                </Modal>
            </div>
        );
    }
}
import { connect } from 'react-redux'
import { TargetListComponent } from '../../components/permission/TargetList'
import {mergeState, fetchTargets, deleteTarget} from '../../actions/permission';

const mapStateToProps = state => {
    return {
        ...state.permission,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        storeState: (state) => {
            dispatch(mergeState(state))
        },
        fetchTargets: () => {
            dispatch(fetchTargets())
        },
        deleteTarget: (targetId) => {
            dispatch(deleteTarget(targetId))
        },
    }
};

export const TargetList = connect(
    mapStateToProps,
    mapDispatchToProps
)(TargetListComponent);

export default TargetList;
import React, {Component} from 'react';
import { withRouter } from 'react-router-dom'
import TopAppBar from "@material/react-top-app-bar";
import MaterialIcon from "@material/react-material-icon";
import {RippleSpan} from "./Ripple";


export class Header extends Component {
    render() {
        const IconSelectProduct = withRouter(({ history }) => (
            <MaterialIcon icon="apps" key="products-icon" style={{ margin: "3px 0 0 -10px" }} onClick={() => { history.push('/building') }} />
        ));

        return (
            <TopAppBar
                className="TopAppBar"
                title={this.props.pageTitle}
                navigationIcon={<MaterialIcon
                    icon='menu'
                    onClick={this.props.onMenuClick}
                />}
                actionItems={[
                    <RippleSpan key="select-product-span"
                                className="TopAppBarRippleSpan mdc-ripple-upgraded mdc-top-app-bar__action-item mdc-top-app-bar__action-item">
                        <IconSelectProduct/>
                    </RippleSpan>
                ]}
            />

            // {/*<header className="mdc-top-app-bar">*/}
            //     {/*<div className="mdc-top-app-bar__row">*/}
            //         {/*<section className="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">*/}
            //             {/*<a className="material-icons mdc-top-app-bar__navigation-icon"*/}
            //                {/*onClick={this.props.onMenuClick}>menu</a>*/}
            //             {/*<span className="mdc-top-app-bar__title" id="pageTitle">{this.props.pageTitle}</span>*/}
            //         {/*</section>*/}
            //
            //         {/*<section className="mdc-top-app-bar__section mdc-top-app-bar__section--align-end" role="toolbar">*/}
            //             {/*<a className="material-icons mdc-top-app-bar__action-item"*/}
            //                {/*onClick={this.props.onFilterClick}><span style={{ fontSize: "0.5em" }}>筛选</span>details</a>*/}
            //
            //
            //             {/*<a className="material-icons mdc-top-app-bar__action-item" aria-label="Print this page"*/}
            //                {/*alt="Print this page">toys</a>*/}
            //         {/*</section>*/}
            //     {/*</div>*/}
            // {/*</header>*/}
        );
    }
}

export default Header;

import React from 'react';
import Button from '@material/react-button';
import logoQQ from '../../static/img/logo/qq-logo.png'
import logoWetest from '../../static/img/logo/wetest.png'
import logoOA from '../../static/img/logo/tencent-oa.png'
import logoOAOuter from '../../static/img/logo/tencent-oa-outer.png'
import logoWechatWork from '../../static/img/logo/wechat-work-logo.png'
import logoGit from '../../static/img/logo/git-white.png'
import { Api } from "../../api";
import {message} from "../../utils/message";
import {Mta} from "../../utils/mta";
import {getUrlParams} from '../../utils/xss';


class LoginCell extends React.Component {
    render() {
        if (!this.props.allowed) {
            return null;
        }

        return (
            <div className={"Login-cell " + (this.props.allowed ? "" : "disable")}
                  onClick={ (e) => {
                      this.props.allowed && this.props.onClick();
                  }}>
                <div className="Login-logo">
                    <img className={this.props.imgClass} src={this.props.logo} alt={this.props.name}/>
                </div>

                {
                    this.props.allowed ? (
                        <Button className="Login-btn">{this.props.name}</Button>
                    ) : (
                        <span><b>{this.props.sysName} </b> 暂不支持 「{this.props.name}」</span>
                    )
                }
                {
                    this.props.children
                }
            </div>
        )
    }
}

export class LoginComponent extends React.Component {
    constructor(props) {
        super(props);

        this.allLoginTypes = ["OA", "QQ", "WECHAT_WORK_WEB", "WETEST", "PASSWORD"];
        this.urlParams = new URLSearchParams(window.location.search);

        this.state = {
            sysId: getUrlParams(this.urlParams, 'sys_id'),
            sysName: getUrlParams(this.urlParams, 'sys_id'),
            allowLoginTypes: []
        };

        this.loginWithType = this.loginWithType.bind(this);
        this.loginOA = this.loginOA.bind(this);
        this.loginOAOuter = this.loginOAOuter.bind(this);
        this.loginQQ = this.loginQQ.bind(this);
        this.loginWechatWorkApp = this.loginWechatWorkApp.bind(this);
        this.loginWechatWorkWeb = this.loginWechatWorkWeb.bind(this);
        this.loginWetest = this.loginWetest.bind(this);
        this.allowLogin = this.allowLogin.bind(this);
        this.needShowLoginTypes = this.needShowLoginTypes.bind(this);

        this.greetingHours = [
            [22, 24, "夜已深, 注意身体哦 ^^"],
            [0, 5, "夜已深, 注意身体哦 ^^"],
            [6, 13, "又是元气满满的一天, 加油 !"],
            [13, 15, "该午休啦, 亲 !"],
            [15, 18, "努力工作，早些回家 ^^"],
            [18, 22, "夜晚的星星都好害羞, 或许是TA们想你了 ?"],
        ]
    }

    componentDidMount() {
        let user_agent = getUrlParams(this.urlParams, 'user_agent');
        if (user_agent && user_agent.includes("wxwork")) {
            this.loginWechatWorkApp();
        }

        this.fetchSysConfig();
    }

    get greeting() {
        let hours = (new Date()).getHours();
        let greeting = "";
        this.greetingHours.forEach((item) => {
            if (item[0] <= hours && hours < item[1]){
                console.log("item, hit", item);
                greeting = item[2];
            }
        });
        return greeting;
    }

    fetchSysConfig() {
        const sysId = getUrlParams(this.urlParams, 'sys_id');

        new Api(this.props.rootState).get(Api.Action.AUTH_SYS_CONFIG, {
            sys_id: sysId,
            }).then((response) => {
            let d = response.data.data;
            console.log("fetchSysConfig, resp:", response.data);
            if (d["allow_login_types"] == null ) {
                message.showError("未注册系统, sys_id: " + sysId);
                return
            }
            this.setState({
                sysName: d["sys_name"],
                // 如不填写, 默认全部开放
                allowLoginTypes: d["allow_login_types"].length > 0 ? d["allow_login_types"] : this.allLoginTypes,
            });
            if (d["allow_login_types"].length === 1) {
                this.loginWithType(d["allow_login_types"][0]);
            }
        }).catch((e) => {
            message.showError("拉取产品信息失败, sys_id: " + sysId + ", error: " + e);
        });
    }

    loginWithType(type) {
        const sysId = getUrlParams(this.urlParams, 'sys_id');
        const checkUri = getUrlParams(this.urlParams, 'check_uri');
        const redirectUri = getUrlParams(this.urlParams, 'redirect_uri');

        console.log("LoginComponent, check_uri:", checkUri, ", redirect_uri:", redirectUri);

        window.location.href = process.env.REACT_APP_TPS_API_BASE_URL + "api/v1/auth/login?type=" + type
            + "&sys_id=" + encodeURIComponent(sysId)
            + "&check_uri=" + encodeURIComponent(checkUri)
            + "&redirect_uri=" + encodeURIComponent(redirectUri);
    }

    loginOA() {
        Mta.clickStat("login__click_oa", {[this.state.sysId]: true});
        this.loginWithType("OA");
    }

    loginOAOuter() {
        Mta.clickStat("login__click_oa_outer", {[this.state.sysId]: true});
        this.loginWithType("OA_OUTER");
    }

    loginQQ() {
        Mta.clickStat("login__click_qq", {[this.state.sysId]: true});
        this.loginWithType("QQ");
    }

    loginWechatWorkApp() {
        Mta.clickStat("login__click_wework_app", {[this.state.sysId]: true});
        this.loginWithType("WECHAT_WORK_APP");
    }

    loginWechatWorkWeb() {
        Mta.clickStat("login__click_wework_web", {[this.state.sysId]: true});
        this.loginWithType("WECHAT_WORK_WEB");
    }

    loginWetest() {
        Mta.clickStat("login__click_wetest", {[this.state.sysId]: true});
        this.loginWithType("WETEST");
    }

    allowLogin(type) {
        return this.state.allowLoginTypes.indexOf(type) > -1
    }

    needShowLoginTypes() {
        return this.state.allowLoginTypes.filter((t) => {
            // 这两个不需要显示
            return ["OA_OUTER", "WECHAT_WORK_APP"].indexOf(t) < 0
        })
    }

    render() {
        console.log("greating", this.greeting);
        let cellNum = this.needShowLoginTypes().length;
        let columns = cellNum > 0 ? 12 / cellNum : 12;
        let sysName = this.state.sysName;

        if (this.state.allowLoginTypes.length === 0) {
            return (
                <h3>
                    {`该系统 (${this.state.sysName}) 尚未允许任何登录方式`}
                </h3>
            )
        }

        return (
            <div className='Login'>
                <div className="Login-sliding-background"/>
                <div className="Login-title">
                    <span>请选择登录方式</span>
                </div>
                <div className="Login-grid">
                        <LoginCell
                            allowed={this.allowLogin("WECHAT_WORK_WEB")}
                            columns={columns}
                            sysName={sysName}
                            name="企业微信"
                            logo={logoWechatWork}
                            onClick={this.loginWechatWorkWeb}
                            imgClass="Login-logo-icon"
                        />

                        <LoginCell
                            name="iOA" logo={logoOA}
                            allowed={this.allowLogin("OA")}
                            imgClass="Login-logo-icon-oa"
                            columns={columns} sysName={sysName}
                            onClick={this.loginOA}
                        >
                        </LoginCell>


                        <LoginCell
                            name="QQ" logo={logoQQ}
                            allowed={this.allowLogin("QQ")}
                            imgClass="Login-logo-icon"
                            columns={columns} sysName={sysName}
                            onClick={this.loginQQ}
                        />

                        <LoginCell
                            name="OA(外网)" logo={logoOAOuter}
                            allowed={this.allowLogin("OA")}
                            imgClass="Login-logo-icon-oa"
                            columns={columns} sysName={sysName}
                            onClick={this.loginOAOuter}
                        >
                        </LoginCell>

                        <LoginCell
                            name="WeTest" logo={logoWetest}
                            allowed={this.allowLogin("WETEST")}
                            imgClass="Login-logo-icon-oa"
                            columns={columns} sysName={sysName}
                            onClick={this.loginWetest}
                        />
                </div>

                <div className="Login-greeting">
                    <p>{this.greeting}</p>
                </div>

                <div className="Login-footer">
                    <div className="Git-logo" onClick={() => {
                        Mta.clickStat("login__click_git", {[this.state.sysId]: true});
                        var win = window.open('https://git.code.oa.com/tencent_cloud_mobile_tools/TenPubService', '_blank');
                        win.focus();
                    }}>
                        <Button>
                            <img className="Git-logo-icon" src={logoGit} alt="logoGit"/>
                        </Button>
                        <span className="Login-footer-msg">开源地址: <b>TenPubService</b></span>
                    </div>
                </div>
            </div>
        )
    }
}

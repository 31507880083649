import {connect} from "react-redux";
import { AddAccessComponent } from "../../components/permission/AddAccessModal";
import {addAccess, fetchTargets, mergeState} from "../../actions/permission";

const mapStateToProps = state => {
    return {
        ...state.permission
    }
};

const mapDispatchToProps = dispatch => {
    return {
        storeState: (state) => {
            dispatch(mergeState(state))
        },
        addAccess: (accessInfo) => {
            dispatch(addAccess(accessInfo))
        },
        fetchTargets: () => {
            dispatch(fetchTargets())
        }
    }
};

export const AddAccess = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddAccessComponent);

export default AddAccess;
import {auth} from './auth'
import {foundation} from './foundation'
import {permission} from "./permission";

export const initialState = {
    auth: {

    },
    foundation: {
        pageTitle: "腾讯公共服务(TPS)",
        loading: false
    },
    permission: {
        // 所选group
        selectedGroup: {
            groupId: 0,
            groupName: "Loading...",
            owners: [{
                user_id: "",
                nick_name: "",
            }],
            members: [{
                user_id: "",
                nick_name: "",
            }],
            accesses: [{
                    target_id: "",
                    access_level: 0,
                }],
            joinLink: "",
        },

        //group列表
        groups: [
            {
                group_id: 0,
                group_name: "Loading...",
            },
        ],
        groupsLoaded: false,
        modalIsOpen: false,
        accessModalIsOpen: false,
    }
};

function makeupState(state) {
    state.foundation = state.foundation ? state.foundation : initialState.foundation;
    if (! state.foundation.loading){
        state.foundation.loading = initialState.foundation.loading;
    }
    state.permission = state.permission ? state.permission : {};
}

export function exportImportantState(state) {
    makeupState(state);

    return {
        foundation: state.foundation,
        permission: {
            selectedGroup: state.permission.selectedGroup,
            joinLink: state.permission.joinLink,
        }
    }
}

export function tpsApp(state = initialState, action) {
    makeupState(state);

    return Object.assign({}, state, {
        auth: auth(state.meta, state, action),
        foundation: foundation(state.foundation, state, action),
        permission: permission(state.permission, state, action),
    })
}

import axios from 'axios';

const instance = axios.create();

// so django is_ajax() will work ...
instance.defaults.headers.common['X-Requested-With'] = "XMLHttpRequest";
instance.defaults.headers.common['SYS-ID'] = "tps";

instance.interceptors.response.use((response) => {
    return response
}, (error) => {
    if (error.response && error.response.data && error.response.data.location) {
        if (! window.location.href.includes("/web/auth/check_token")) {
            console.log("axios, at " + window.location + ", will redirect to:", error.response.data.location);
            window.location = error.response.data.location;
        }
    } else {
        return Promise.reject(error)
    }
});

export class Api {
    constructor(reduxState) {
        this.state = reduxState;
    }

    get(action, params) {
        if (!action || !action.url) {
            throw Error('api, not found url for action:' + action.type)
        }
        return instance.get(action.url, {
            params: params,
        })
    }

    post(action, params, ContentType=null) {
        if (!action || !action.url) {
            throw Error('api, not found url for action:' + action.type)
        }

        let config = {
          headers: {
              "Content-Type": ContentType ? ContentType : "application/json"
          }
        };
        console.log("post params:", params);
        return instance.post(action.url, params, config)
    }

    delete(action, params){
        if (!action || !action.url) {
            throw Error('api, not found url for action:' + action.type)
        }
        console.log("delete params:", params)

        return instance.delete(action.url, {
            params: params
        })
    }
}

Api.Action = {
    AUTH_SYS_CONFIG: {
        url: "/api/v1/auth/sys_config",
    },
    AUTH_CHECK_TICKET: {
        url: "/api/v1/auth/check_ticket",
    },
    AUTH_CHECK_TOKEN: {
        url: "/api/v1/auth/check_token",
    },
    API_BRIDGE_TAPD_TENCENT: {
        url: "/api/v1/apibridge/tapd_tencent",
    },
    API_GROUP_LIST_USER_GROUP: {
        url: "/api/v1/permission/group/",
    },
    API_GROUP_ADD_GROUP: {
        url: "/api/v1/permission/group/",
    },
    API_GROUP_ADD_ACCESS: {
        url: "/api/v1/permission/access/create_group_to_target_access/",
    },
    API_GROUP_CHANGE_GROUP_ACCESS: {
        url: "/api/v1/permission/access/update_group_to_target_access/",
    },
    API_GROUP_JOIN_GROUP: {
        url: "/api/v1/permission/group/join_group/",
    },
    API_TARGET_LIST: {
        url: "/api/v1/permission/target/",
    },
    API_GROUP_SHOW_DETAIL(pk) {
        return {url: `/api/v1/permission/group/${pk}/`};
    },
    API_GROUP_CREATE_JOIN_GROUP_LINK(pk){
        return { url: `/api/v1/permission/group/${pk}/create_join_group_link/`,};
    },
    API_GROUP_DELETE_OWNER(pk) {
        return { url: `/api/v1/permission/group/${pk}/delete_owner/`}
    },
    API_GROUP_DELETE_MEMBER(pk) {
        return { url: `/api/v1/permission/group/${pk}/delete_member/`}
    },
    API_TARGET_SHOW_DETAIL(pk) {
        return {url: `/api/v1/permission/target/${pk}/`};
    }
};

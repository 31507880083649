import {showMessage} from '../components/foundation/Snackbar'


export function showInfo(msg) {
    let options = {
        timeout: 5000
    };
    showMessage(msg, options);
}

export function showError(msg) {
    let options = {
        timeout: 15000
    };
    showMessage(msg, options);
}

export const message = {
    showInfo: showInfo,
    showError: showError,
};
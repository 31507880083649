import React from "react";
import {MDCSnackbar} from '@material/snackbar';

export const SnackBar = ()=> {
    return (
        <div className="mdc-snackbar mdc-snackbar--align-start"
             aria-live="assertive"
             aria-atomic="true"
             aria-hidden="true">
            <div className="mdc-snackbar__text"/>
            <div className="mdc-snackbar__action-wrapper">
                <button type="button" className="mdc-snackbar__action-button"/>
            </div>
        </div>

    )
};

export function showMessage(message, options) {
    const snackBar = new MDCSnackbar(document.querySelector('.mdc-snackbar'));
    const dataObj = {
        message: message,
        // actionText: actionText,
        // actionHandler: actionHandler,
        ...options
    };

    snackBar.show(dataObj);
}





import React from 'react';
import {Cell, Grid, Row} from '@material/react-layout-grid';
import Button from '@material/react-button';
import TargetList from "../../containers/permission/TargetList";
import TargetDetail from "../../containers/permission/TargetDetail";


export class TargetComponent extends React.Component {

    constructor(props) {
        super(props);
        this.handleChangeModalState = this.handleChangeModalState.bind(this);
        this.state = { open: false}
    }

    render() {

        console.log("TargetComponent, render props", this.props);
        return(
            <React.Fragment>
                <div className='Target'>
                    <Grid>
                        <Row>
                            <Cell columns={3}>
                                <Button
                                    className="AddTarget"
                                    raised={true}
                                    onClick={() => this.handleChangeModalState(true)}
                                    disabled={true}
                                >
                                    添加资源
                                </Button>
                                <TargetList/>
                            </Cell>
                            <Cell columns={9}>
                                <TargetDetail/>
                            </Cell>
                            {/*<AddTarget handleChangeModalState={isOpen => this.handleChangeModalState(isOpen)}/>*/}
                        </Row>
                    </Grid>
                </div>
            </React.Fragment>
        )
    }


    handleChangeModalState(isOpen) {
        this.setState(
            {
                open:isOpen
            });
    }

}
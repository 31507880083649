import {connect} from "react-redux";
import { AddGroupComponent } from "../../components/permission/AddGroupModal";
import {addGroup, mergeState} from "../../actions/permission";

const mapStateToProps = state => {
    return {
        ...{
            modalIsOpen: state.permission.modalIsOpen
        },
    }
};

const mapDispatchToProps = dispatch => {
    return {
        storeState: (state) => {
            dispatch(mergeState(state))
        },
        addGroup: (groupInfo) => {
            dispatch(addGroup(groupInfo))
        },
    }
};

export const AddGroup = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddGroupComponent);

export default AddGroup;
import React from 'react';
import { render } from 'react-dom';
import { Router, Route } from 'react-router'
import { Provider } from 'react-redux'
import createBrowserHistory from 'history/createBrowserHistory'
import {createStore} from "redux";
import {tpsApp, exportImportantState, initialState} from "./reducers";
import App from './App'

import './index.css';
import * as serviceWorker from './serviceWorker';

const persistedState = localStorage.getItem('reduxState') ?
    JSON.parse(localStorage.getItem('reduxState')) : initialState;
console.log("App State persistedState: ", persistedState);

const store = createStore(tpsApp, persistedState);

store.subscribe(() => {
    let state = store.getState();
    console.log("App State:", state);
    localStorage.setItem('reduxState', JSON.stringify(exportImportantState(state)));
});

export function storeDispatch(action) {
    store.dispatch(action);
}

const history = createBrowserHistory({
    basename: "/web",
});

let elem = (
    <Provider store={store}>
        <Router history={history} basename={process.env.PUBLIC_URL}>
            <Route path="/" component={App} />
        </Router>
    </Provider>
);

render(elem, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import {Header} from './Header';
import {LoadingComponent} from './Loading'
import {Navigation} from './Navigation';
import {SnackBar} from './Snackbar'
import {message} from '../../utils/message'
import $ from 'jquery';


export const LayoutContext = React.createContext({
    setRedirect: null
});
let showedHello = false;


export class LayoutComponent extends Component {
    constructor(props) {
        super(props);

        this.toggleModal = this.toggleModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onNavigationClick = this.onNavigationClick.bind(this);
        this.setRedirect = this.setRedirect.bind(this);
        this.renderRedirect = this.renderRedirect.bind(this);

        this.state = {
            openModal: false,
            redirect: false,
            redirectPath: "/",
        }
    }

    render() {
        console.log("LayoutComponent, render again ...", this.props.pageTitle);

        return (
            <LayoutContext.Provider value={ {setRedirect: this.setRedirect} }>
                <div>
                    {this.renderRedirect()}
                    <Navigation openModal={this.state.openModal}
                                onNavigationClick={(e) => this.onNavigationClick(e)}
                                appName={this.props.appName}
                    />

                    <div className="mdc-drawer-app-content">
                        <Header onMenuClick={this.toggleModal}
                                onFilterClick={(e) => this.toggleFilter(e)}
                                pageTitle={this.props.pageTitle}
                                filterHit={this.state.filterHit}
                                filterIcon={this.state.filterIcon}
                        />
                        <div className="header-div"/>

                        <LoadingComponent loading={this.props.loading}/>

                        <div className="Layout-body" id="LayoutBody" onClick={this.closeModal} >
                            { this.props.children }
                        </div>
                    </div>
                    <SnackBar/>
                </div>
            </LayoutContext.Provider>
        );
    }

    setRedirect(redirectPath) {
        this.setState({
            redirect: true,
            redirectPath: redirectPath
        })
    }

    renderRedirect() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectPath} />
        }
    }

    componentDidMount() {
        console.log("LayoutComponent, componentDidMount again ...", this.state.pageTitle);
        if (!showedHello) {
            message.showInfo("欢迎使用: 腾讯公共服务 :)");
            showedHello = true;
        }
    }

    toggleModal(e) {
        e.preventDefault();
        this.setState({
            openModal: !this.state.openModal,
        });
    }

    closeModal(e) {
        e.preventDefault();
        this.setState({
            openModal: false,
        });
    }

    onNavigationClick(e) {
        let title = e.currentTarget.getAttribute("data-title");
        title = "腾讯公共服务(TPS) - " + title;
        console.log("onNavigationClick, ", title);

        // onNavigationClick 触发时, 界面会发生跳转, 会新建一个 Layout...
        // 实时上下面的 this.setState() 是不生效的
        this.props.storeState({
            pageTitle: title,
        });

        // 保底方案
        setTimeout(() => {
            $(".TopAppBar .mdc-top-app-bar__title").text(title);
            document.title = title;
        }, 200);
    }
}

export default LayoutComponent;

import React from "react";
import List, {ListItem, ListItemText} from '@material/react-list';

export class TargetDetailComponent extends React.Component {

    render() {

        let {selectedTarget, targets} = this.props;
        selectedTarget = selectedTarget ? selectedTarget: {};
        targets = targets ? targets:[];
        let target = targets.find(target => target.target_id === selectedTarget);
        console.log("TargetDetailComponent, target", target);

        return (
            <List>
                {
                    target && Object.keys(target.remark).map((key, index) => {
                        console.log("aaa:" + key, target.remark[key]);
                        return(
                            <ListItem key={index}>
                                <b><ListItemText
                                    primaryText={key + ' : ' + target.remark[key]}
                                /></b>
                            </ListItem>
                        )
                    })
                }
            </List>
        )
    }
}
import { connect } from 'react-redux'
import { GroupListComponent } from '../../components/permission/GroupList'
import {mergeState, fetchGroups, changeGroup, deleteGroup} from '../../actions/permission';

const mapStateToProps = state => {
    return {
        ...state.permission,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        storeState: (state) => {
            dispatch(mergeState(state))
        },
        fetchGroups: () => {
            dispatch(fetchGroups())
        },
        changeGroup: (groupId) => {
            dispatch(changeGroup(groupId))
        },
        deleteGroup: (groupId) => {
            dispatch(deleteGroup(groupId))
        },
    }
};

export const GroupList = connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupListComponent);

export default GroupList;
import React from 'react';
import { HashLoader } from 'react-spinners';

export class LoadingComponent extends React.Component {
    render() {
        console.log("LoadingComponent, loading: ", this.props.loading);

        return (
            <div className='sweet-loading Loading'>
                <HashLoader
                    sizeUnit={"px"}
                    size={150}
                    color={'#123abc'}
                    loading={this.props.loading}
                />
            </div>
        )
    }
}

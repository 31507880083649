import React, {Component} from "react";
import {Route, Switch} from "react-router-dom";
import {PAGES_IN_LAYOUT} from "../../models/route";
import {Layout} from "../../containers/foundation/Layout";


export class LayoutWithRouteComponent extends Component {

    componentDidMount() {
        let layout = PAGES_IN_LAYOUT.find((x)=>{
            return x.path===this.props.location.pathname
        });
        this.props.storeState({
            pageTitle: "腾讯公共服务(TPS) - " + layout.pageTitle,
        });
    }

    render() {
        return (
            <Layout>
                <Switch>
                    {
                        PAGES_IN_LAYOUT.map((page, index) => {
                            return (
                                <Route key={index} exact path={page.path} component={page.component}/>
                            )
                        })
                    }
                </Switch>
            </Layout>
        );
    }
}

export default LayoutWithRouteComponent;

import React, { Component } from 'react';
import {Route, Switch} from 'react-router-dom';
import { LayoutTinyWithRouteComponent } from './components/foundation/LayoutTinyWithRoute'
import { LayoutWithRoute } from "./containers/foundation/LayoutWithRoute";
import {PAGES_IN_LAYOUT, PAGES_IN_LAYOUT_TINY} from './models/route'

import './App.css'

class App extends Component {
    render() {
        const App = () => (
            <Switch>
                {
                    PAGES_IN_LAYOUT_TINY.map((page, index) => {
                        return (
                            <Route key={index} exact path={page.path} component={LayoutTinyWithRouteComponent}/>
                        )
                    })
                }
                {
                    PAGES_IN_LAYOUT.map((page, index) => {
                        return (
                            <Route key={index} exact path={page.path} component={LayoutWithRoute}/>
                        )
                    })
                }
            </Switch>
        );
        return (
            <App/>
        );
    }
}


export default App;

import { connect } from 'react-redux'

import { TapdTencentComponent } from '../../components/apibridge/TapdTencent';

const mapStateToProps = state => {
    return {
        rootState: state,
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export const TapdTencent = connect(
    mapStateToProps,
    mapDispatchToProps
)(TapdTencentComponent);

export default TapdTencent;
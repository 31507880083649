import MtaH5 from 'mta-h5-analysis';


MtaH5.init({
    "sid":'500661850',
    "cid":'500661853',
    "autoReport":1,
    "senseHash":0,
    "senseQuery":0,
    "performanceMonitor":1,
    "ignoreParams":["token", "ticket", "code"]   //开启url参数上报时，可忽略部分参数拼接上报
});

export const Mta = MtaH5;


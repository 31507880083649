import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {PAGE_ROUTES_BY_GROUP} from '../../models/route'


export class Navigation extends Component {
    render() {

        return (
            <aside className={"mdc-drawer mdc-drawer--modal" + (this.props.openModal ? " mdc-drawer--open" : "")}>
                <div className="mdc-drawer__header">
                    <h3 className="mdc-drawer__title">腾讯公共服务(TPS)</h3>
                    <h6 className="mdc-drawer__subtitle">简单、实用、开放</h6>
                    <hr/>
                    <div className="product-select" >{this.props.appName}</div>
                </div>

                <div className="mdc-drawer__content">
                    <nav className="mdc-list">
                        {
                            PAGE_ROUTES_BY_GROUP.map((group, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <h6 className="mdc-list-group__subheader">{group.groupName}</h6>
                                        {
                                            group.pages.map((page, index) => {
                                                return (
                                                    <Link key={index}
                                                          className="mdc-list-item mdc-list-item--activated"
                                                          to={page.path}
                                                          data-title={page.pageTitle}
                                                          onClick={this.props.onNavigationClick.bind(this)}>
                                                        <i className="material-icons mdc-list-item__graphic">{page.icon ? page.icon : "crop_3_2"}</i>
                                                        <span className="mdc-list-item__text">{page.pageTitle}</span>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </nav>
                </div>
            </aside>
        );
    }
}

export default Navigation;
import React from 'react';
import {Cell, Grid, Row} from '@material/react-layout-grid';


export class PermissionDeny extends React.Component {

    render() {

        return(
            <div className='NoPermission'>
                <Grid className="NoPermission-grid">
                    <Row>
                        <Cell columns={2}/>

                        <Cell columns={8}>
                            <h1>对不起，您还没有权限访问该页面 ...</h1>

                            <p>请找相关管理员添加权限.</p>
                        </Cell>
                        <Cell columns={2}/>
                    </Row>
                </Grid>
            </div>
        )
    }

}
import { mergeState as mergePermissionState, mergeSelectGroupState as mergeSelectGroupStateAction} from "../../actions/permission";
import {mergeState} from '../utils'
import { Api } from "../../api";
import { Loading } from '../../utils/loading';
import { storeDispatch } from '../../index';
import {message} from "../../utils/message";

function fetchGroups (state, rootState) {
    let merge = { groupsLoaded: false };

    merge.groupsPromise = (async () => {
        let groups = [];
        await new Api(rootState).get(Api.Action.API_GROUP_LIST_USER_GROUP,{

        }).then((response) => {
            groups = response.data.data;
            console.log("group data:", groups)
        });
        return groups;
    })();
    Loading.show();
    // WARN: Redux 不推荐在 reducer 里面调用 store.dispatch(), 这里为了简单, 就直接调了 ...
    merge.groupsPromise.then(
        res => {
            console.log("fetchGroups, get groups: ", res);
            storeDispatch(mergePermissionState({
                groups: res["group_list"],
                groupsLoaded: true,
            }));

            Loading.hide();
        }
    ).catch((e) => {
        console.log("fetchGroups, get groups error", e);
        storeDispatch(mergePermissionState({
            groups: [],
            groupsLoaded: true,
        }));
        Loading.hide();
    });
    return mergeState(state, merge)
}

function changeGroup(state, rootState, groupId) {
    if(!groupId){
        return state;
    }
    new Api(rootState).get(Api.Action.API_GROUP_SHOW_DETAIL(`${groupId}`),{

    }).then((response) => {
        if(response.status !== 200 || response.data.code !== 10000){
            message.showError("changeGroup error: ", response.data.msg);
            return state;
        }
        let group = response.data.data;
        console.log("changeGroup, group:", group);

        // 删除member中包括的owner元素，为了避免前端重复显示人员
        let member = group.members.filter( function (el) {
            return !group.owners.find( o => o.user_id === el.user_id );
        });
        storeDispatch(mergeSelectGroupStateAction({
            groupId: groupId,
            owners: group.owners,
            members: member,
            accesses: group.accesses,
        }));

    }).catch((e) => {
        console.log("changeGroup, error", e);
        message.showError("出现了一些问题:", e)
    });

    return state;
}

function addGroup(state, rootState, groupInfo) {
    if(!groupInfo || !groupInfo.groupName){
        message.showError("请输入用户组名。");
        return state;
    }
    let params = {
        group_name: groupInfo.groupName,
        owners: groupInfo.groupOwners ? groupInfo.groupOwners : [],
        members: groupInfo.groupMembers ? groupInfo.groupMembers : [],
    };
    console.log("params:",params);
    new Api(rootState).post(Api.Action.API_GROUP_ADD_GROUP,{
        group_name: groupInfo.groupName,
        owners: groupInfo.groupOwners ? groupInfo.groupOwners : [],
        members: groupInfo.groupMembers ? groupInfo.groupMembers : [],
    }).then((response) => {
        if(response.status !==200 || response.data.code !== 10000){
            console.log("addGroup response error.", response);
            message.showError("创建用户组失败：", response.data.msg);
        }
        fetchGroups(state, rootState);
    }).catch((e) => {
        console.log("addGroup, error", e);
        message.showError("出现了一些问题:", e)
    });
    return state;
}

function addAccess(state, rootState, accessInfo) {
    if(!accessInfo){
        return state;
    }
    let params = {
        group_id: accessInfo.groupId,
        target_id: accessInfo.targetId,
        access_level: parseInt(accessInfo.accessLevel,10),
    };
    console.log("params:",params);
    new Api(rootState).post(Api.Action.API_GROUP_ADD_ACCESS,params).then((response) => {
        if(response.status !==200 || response.data.code !== 10000){
            console.log("addAccess response error.", response);
            message.showError("创建权限失败：", response.data.msg);
        }
        changeGroup(state, rootState, accessInfo.groupId);
    }).catch((e) => {
        console.log("addAccess, error", e);
        message.showError("出现了一些问题:", e)
    });
    return state;
}

function deleteGroup(state, rootState, groupId) {
    if(!groupId || groupId===0){
        return state;
    }
    new Api(rootState).delete(Api.Action.API_GROUP_SHOW_DETAIL(`${groupId}`),{

    }).then((response) => {
        console.log("deleteGroup response:", response);
        if(response.status !== 200 || response.data.code !== 10000){
            message.showError("deleteGroup error: ", response.data.msg);
            return state;
        }
        console.log("deleteGroup, groupId:", groupId);
        // 刷新state状态
        fetchGroups(state, rootState);
    }).catch((e) => {
        console.log("deleteGroup, error", e);
        message.showError("出现了一些问题:", e)
    });
    return state;
}

function deleteOwner(state,rootState, groupId, ownerId) {
    if(!groupId || groupId===0 || !ownerId || ownerId===0){
        return state;
    }
    new Api(rootState).post(Api.Action.API_GROUP_DELETE_OWNER(`${groupId}`),{
        owners: [`${ownerId}`]
    }).then((response) => {
        console.log("deleteOwner response:", response);
        if(response.status !== 200 || response.data.code !== 10000){
            message.showError("deleteOwner error: ", response.data.msg);
            return state;
        }
        console.log("deleteOwner, groupId:", groupId, ",ownerId", ownerId);
        // 刷新state状态
        changeGroup(state, rootState, groupId);
    }).catch((e) => {
        console.log("deleteOwner, error", e);
        message.showError("出现了一些问题:", e)
    });
    return state;
}

function deleteMember(state,rootState, groupId, memberId) {
    if(!groupId || groupId===0 || !memberId || memberId===0){
        return state;
    }
    new Api(rootState).post(Api.Action.API_GROUP_DELETE_MEMBER(`${groupId}`),{
        members: [`${memberId}`]
    }).then((response) => {
        console.log("deleteMember response:", response);
        if(response.status !== 200 || response.data.code !== 10000){
            message.showError("deleteMember error: ", response.data.msg);
            return state;
        }
        console.log("deleteMember, groupId:", groupId, ",memberId", memberId);
        // 刷新state状态
        changeGroup(state, rootState, groupId);
    }).catch((e) => {
        console.log("deleteMember, error", e);
        message.showError("出现了一些问题:", e)
    });
    return state;
}

function changeGroupAccess(state,rootState, groupId, targetId, level) {
    if(!groupId || !targetId || level){
        return state;
    }
    new Api(rootState).post(Api.Action.API_GROUP_CHANGE_GROUP_ACCESS,{
        target_id: targetId,
        group_id: groupId,
        access_level: level
    }).then((response) => {
        console.log("changeGroupAccess response:", response);
        if(response.status !== 200 || response.data.code !== 10000){
            message.showError("changeGroupAccess error: ", response.data.msg);
            return state;
        }
        console.log("changeGroupAccess, groupId:", groupId, ",targetId:", targetId, ",level:", level);
        // 刷新state状态
        changeGroup(state, rootState, groupId);
    }).catch((e) => {
        console.log("changeGroupAccess, error", e);
        message.showError("出现了一些问题:", e)
    });
    return state;
}

function createGroupLink(state,rootState, groupId, roleType) {
    if(!groupId){
        return state;
    }
    new Api(rootState).get(Api.Action.API_GROUP_CREATE_JOIN_GROUP_LINK(`${groupId}`),{
        role_type: roleType
    }).then((response) => {
        console.log("createGroupLink response:", response);
        if(response.status !== 200 || response.data.code !== 10000){
            message.showError("createGroupLink error: ", response.data.msg);
            return state;
        }
        storeDispatch(mergeSelectGroupStateAction({
            joinLink: response.data.data.link
        }));
        console.log("createGroupLink, groupId:", groupId, ",roleType:", roleType);
    }).catch((e) => {
        console.log("createGroupLink, error", e);
        message.showError("出现了一些问题:", e)
    });
    return state;
}

function mergeSelectGroupState(state, stateToMerge) {
    state.selectedGroup = mergeState(state.selectedGroup || {}, stateToMerge);
    return state
}

function fetchTargets (state, rootState) {
    let merge = { targetsLoaded: false };

    merge.targetsPromise = (async () => {
        let targets = [];
        await new Api(rootState).get(Api.Action.API_TARGET_LIST,{

        }).then((response) => {
            targets = response.data.data;
            console.log("target data:", targets)
        });
        return targets;
    })();
    Loading.show();
    // WARN: Redux 不推荐在 reducer 里面调用 store.dispatch(), 这里为了简单, 就直接调了 ...
    merge.targetsPromise.then(
        res => {
            storeDispatch(mergePermissionState({
                targets: res["targets"],
                targetsLoaded: true,
            }));

            Loading.hide();
        }
    ).catch((e) => {
        console.log("fetchTargets, get target error", e);
        storeDispatch(mergePermissionState({
            targets: [],
            targetsLoaded: true,
        }));
        Loading.hide();
    });
    return mergeState(state, merge)
}

function deleteTarget(state, rootState, targetId) {
    if(!targetId || targetId===""){
        return state;
    }
    new Api(rootState).delete(Api.Action.API_TARGET_SHOW_DETAIL(`${targetId}`),{

    }).then((response) => {
        console.log("deleteTarget response:", response);
        if(response.status !== 200 || response.data.code !== 10000){
            message.showError("deleteTarget error: ", response.data.msg);
            return state;
        }
        console.log("deleteTarget, targetId:", targetId);
        // 刷新state状态
        (async() => fetchTargets(state, rootState))();
    }).catch((e) => {
        console.log("deleteTarget, error", e);
        message.showError("出现了一些问题:", e)
    });
    return state;
}

export const permission = (state, rootState, action) => {
    switch (action.type) {
        case 'PERMISSION_MERGE_STATE':
            return mergeState(state, action.stateToMerge);
        case 'PERMISSION_MERGE_SELECT_GROUP_STATE':
            return mergeSelectGroupState(state, action.stateToMerge);
        case 'PERMISSION_FETCH_GROUPS':
            return fetchGroups(state, rootState);
        case 'PERMISSION_CHANGE_GROUP':
            return changeGroup(state,rootState, action.groupId);
        case 'PERMISSION_ADD_GROUP':
            return addGroup(state, rootState, action.groupInfo);
        case 'PERMISSION_ADD_ACCESS':
            return addAccess(state, rootState, action.accessInfo);
        case 'PERMISSION_DELETE_GROUP':
            return deleteGroup(state,rootState, action.groupId);
        case 'PERMISSION_DELETE_OWNER':
            return deleteOwner(state,rootState, action.groupId, action.ownerId);
        case 'PERMISSION_DELETE_MEMBER':
            return deleteMember(state,rootState, action.groupId, action.memberId);
        case 'PERMISSION_CHANGE_GROUP_ACCESS':
            return changeGroupAccess(state,rootState, action.groupId, action.targetId, action.level);
        case 'PERMISSION_CREATE_ADD_GROUP_LINK':
            return createGroupLink(state,rootState, action.groupId, action.roleType);
        case 'PERMISSION_FETCH_TARGETS':
            return fetchTargets(state, rootState);
        case 'PERMISSION_DELETE_TARGET':
            return deleteTarget(state,rootState, action.targetId);
        default:
            return state
    }
};

export default permission
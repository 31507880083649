import React, {Component} from 'react';


export class PageNotFound extends Component {
    render() {
        return (
            <div className="PageNotFound">
                <h3 className="show-message">Page Not Found</h3>

            </div>
        );
    }
}

export class PageBuilding extends Component {
    render() {
        return (
            <div className="PageBuilding">
                <h1 className="show-message">建设中, 敬请期待 ...</h1>
            </div>
        );
    }
}

export class PageSelect extends Component {
    render() {
        return (
            <div className="PageSelect">
                <h1 className="show-message"> 请点击左侧菜单栏选择相应功能... </h1>
            </div>
        )
    }
}

export class NoResultsFound extends Component {
    render() {
        return (
            <div className="NoResultsFound">
                <h2 className="NoResultsFound-msg">没有查询到数据</h2>

                <h4>建议: </h4>
                <li className="EmptyResp-msg">修改查询条件</li>
            </div>
        );
    }
}

export default PageNotFound;

import React from 'react';
import {Sankey} from 'react-vis';


export class ResourceFlow extends React.Component {
    render() {

        const nodes = [{name: 'a'}, {name: 'b'}, {name: 'c'}];
        const links = [
            {source: 0, target: 1, value: 10},
            {source: 0, target: 2, value: 20},
            {source: 1, target: 2, value: 20}
        ];

        return (
            <Sankey
                nodes={nodes}
                links={links}
                width={200}
                height={200}
            />
        )
    }
}


import connect from "react-redux/es/connect/connect";
import { UserGroupComponent } from "../../components/permission/UserGroup";
import { mergeState } from "../../actions/permission";

const mapStateToProps = state => {
    return {
        ...state.permission,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        storeState: (state) => {
            dispatch(mergeState(state))
        }
    }
};
export const UserGroup = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserGroupComponent);

export default UserGroup;
import { connect } from 'react-redux'

import { TokenComponent } from '../../components/auth/Token';

const mapStateToProps = state => {
    return {
        rootState: state,
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export const Token = connect(
    mapStateToProps,
    mapDispatchToProps
)(TokenComponent);

export default Token;
import { PageBuilding } from '../components/foundation/Helper'
import { PageSelect } from "../components/foundation/Helper";
import { PermissionDeny } from '../components/permission/PermissionDeny'
import { ResourceFlow } from '../components/metrics/ResourceFlow'
import { UserGroup } from '../containers/permission/UserGroup';
import { Login } from  "../containers/auth/Login";
import { Ticket } from "../containers/auth/Ticket";
import { Token } from "../containers/auth/Token";
import { TapdTencent } from "../containers/apibridge/TapdTencent";
import { JoinGroupComponent } from "../components/permission/JoinGroup";
import {TargetComponent} from "../components/permission/Target";

export const PAGE_ROUTES_BY_GROUP = [
    {
        groupName: "权限管理",
        pages: [
            {pageTitle: "平台管理", path: "/permission/sys", icon: "table_chart", component: PageBuilding},
            {pageTitle: "资源管理", path: "/permission/target", icon: "apps", component: TargetComponent},
            {pageTitle: "用户组管理", path: "/permission/group", icon: "group", component: UserGroup},
        ]
    },
];

export const PAGES_IN_LAYOUT = [
    {path: "/", pageTitle: "..", icon: "call_split", component: PageSelect},
    {path: "/building", pageTitle: "..", icon: "call_split", component: PageBuilding},
    {path: "/auth/check_ticket", pageTitle: "验证 OA Ticket", icon: "lock_open", component: Ticket},
    {path: "/apibridge/tapd_tencent", pageTitle: "Tapd Bridge", icon: "call_split", component: TapdTencent},
    {path: "/permission/no_permission", pageTitle: "无权限", icon: "not_interested", component: PermissionDeny},
    {path: "/permission/group", pageTitle: "用户组管理", icon: "group", component: UserGroup},
    {path: "/permission/group/join_group", pageTitle: "加入用户组", icon: "group", component: JoinGroupComponent},
    {path: "/permission/sys", pageTitle: "平台管理", icon: "table_chart", component: PageBuilding},
    {path: "/permission/target", pageTitle: "资源管理", icon: "apps", component: TargetComponent},
    {path: "/metrics/resource_flow", pageTitle: "ResourceFlow", icon: "call_split", component: ResourceFlow},
];

export const PAGES_IN_LAYOUT_TINY = [
    {path: "/auth/login", pageTitle: "登录", icon: "perm_identity", component: Login},
    {path: "/auth/check_token", pageTitle: "验证 Token", icon: "lock_open", component: Token},
];
import { connect } from 'react-redux'

import {LoginComponent} from '../../components/auth/Login';

const mapStateToProps = state => {
    return {
        rootState: state,
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export const Login = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginComponent);

export default Login;
import { connect } from 'react-redux'

import {mergeState} from '../../actions/foundation';
import {LayoutComponent} from '../../components/foundation/Layout';

const mapStateToProps = state => {
    return {
        ...state.foundation,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        storeState: (state) => {
            dispatch(mergeState(state))
        },
    }
};

export const Layout = connect(
    mapStateToProps,
    mapDispatchToProps
)(LayoutComponent);

export default Layout;

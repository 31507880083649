import React, {Component} from "react";
import {Route, Switch} from "react-router-dom";
import {PAGES_IN_LAYOUT_TINY} from "../../models/route";
import {LayoutTiny} from "../../containers/foundation/LayoutTiny";


export class LayoutTinyWithRouteComponent extends Component {
    render() {
        return (
            <LayoutTiny>
                <Switch>
                    {
                        PAGES_IN_LAYOUT_TINY.map((page, index) => {
                            return (
                                <Route key={index} exact path={page.path} component={page.component}/>
                            )
                        })
                    }
                </Switch>
            </LayoutTiny>
        );
    }
}

export default LayoutTinyWithRouteComponent;

import xss from 'xss';
import {assert, isUrl} from "./check";

var xssInst = new xss.FilterXSS();

export const getUrlParams = function (urlParams, field) {
    return xssInst.process(urlParams.get(field));
};

export const getUrlFromUrlParams = function (urlParams, field) {
    let value = getUrlParams(urlParams, field);
    assert(isUrl(value), `field ${field} MUST be url: ${value}`);
    return value;
};

import React from 'react';
import {Cell, Grid, Row} from '@material/react-layout-grid';
import { GroupList} from "../../containers/permission/GroupList";
import { GroupDetail } from "../../containers/permission/GroupDetail";
import { AddGroup } from '../../containers/permission/AddGroupModal';


export class UserGroupComponent extends React.Component {

    constructor(props) {
        super(props);
        this.openModal = this.openModal.bind(this);
        this.state = { modalIsOpen : false}
    }

    render() {
        return(
            <React.Fragment>
                <div className='UserGroup'>
                    <Grid className="UserGroup-grid">
                        <Row>
                            <Cell columns={3}>
                                <button className="mdc-button mdc-button--raised" style={{marginLeft: "15px"}} onClick={this.openModal}>
                                    <span className="mdc-button__label">添加用户组</span>
                                </button>
                                <GroupList/>
                            </Cell>
                            <Cell columns={9}>
                                <GroupDetail/>
                            </Cell>
                            <AddGroup/>
                        </Row>
                    </Grid>
                </div>
            </React.Fragment>
        )
    }

    openModal(e){
        e.preventDefault();
        this.props.storeState({
            modalIsOpen: true,
        });
    }

}
export function isUrl(s) {
    var regexp = /^(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
    return regexp.test(s);
}

export function assert(condition, message) {
    if (!condition) {
        throw message || "Assertion failed";
    }
}

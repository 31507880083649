import React from 'react';
import {Cell, Grid, Row} from '@material/react-layout-grid';
import {message} from '../../utils/message';
import {getUrlParams, getUrlFromUrlParams} from '../../utils/xss';
import { Api } from '../../api';
import $ from 'jquery';
import {assert, isUrl} from "../../utils/check";


export class TicketComponent extends React.Component {
    constructor(props) {
        super(props);
        this.getOaIpCallback = this.getOaIpCallback.bind(this);
        this.checkTokenOA = this.checkTokenOA.bind(this);
        this.checkTokenOAOuter = this.checkTokenOAOuter.bind(this);
        this.checkTokenQQ = this.checkTokenQQ.bind(this);
        this.checkTokenWechatWork = this.checkTokenWechatWork.bind(this);
        this.whenGetResp = this.whenGetResp.bind(this);
    }

    componentDidMount() {
        let urlParams = new URLSearchParams(window.location.search);
        let type = getUrlParams(urlParams, 'type');
        switch (type) {
            case "OA":
                this.checkTokenOA();
                break;
            case "OA_OUTER":
                this.checkTokenOAOuter();
                break;
            case "QQ":
                this.checkTokenQQ();
                break;
            case "WECHAT_WORK_APP":
                this.checkTokenWechatWork();
                break;
            case "WECHAT_WORK_WEB":
                this.checkTokenWechatWork();
                break;
            case "WETEST":
                this.checkTokenWeTest();
                break;
            default:
                message.showError("the auth type SHOULD NOT be " + type);
                break;
        }
    }

    whenGetResp(promise, sysId, checkUri, redirectUri) {
        promise.then((response) => {
            let d = response.data.data;
            if (response.data.code !== 10000 || d == null) {
                message.showError("鉴权失败" + JSON.stringify(response.data));
                return
            }
            console.log("check ticket, resp:", JSON.stringify(response.data));
            let connector = checkUri.includes("?") ? "&" : "?"
            window.location.href = checkUri
                + connector + "sys_id=" + encodeURIComponent(sysId)
                + "&token=" + encodeURIComponent(d["token"])
                + "&user_id=" + encodeURIComponent(d["user_id"])
                + "&user_name=" + encodeURIComponent(d["user_name"])
                + "&user_nick=" + encodeURIComponent(d["user_nick"])
                + "&user_type=" + encodeURIComponent(d["user_type"])
                + "&redirect_uri=" + encodeURIComponent(redirectUri);
        }).catch((e) => {
            console.log("鉴权失败, 请联系 「腾讯公共服务」 成员协助解决, ", e);
            message.showError("鉴权失败, 请联系 「腾讯公共服务」 成员协助解决, ", e);
        });
    }

    checkTokenOA() {
        this.fetchOaIp(this.getOaIpCallback)
    }

    checkTokenOAOuter() {
        let urlParams = new URLSearchParams(window.location.search);
        let sysId = getUrlParams(urlParams, 'sys_id');
        let type = getUrlParams(urlParams, 'type');
        let code = getUrlParams(urlParams, 'code');
        let checkUri = getUrlFromUrlParams(urlParams, 'check_uri');
        let redirectUri = getUrlFromUrlParams(urlParams, 'redirect_uri');
        assert(isUrl(checkUri), "redirect_uri is not url: " + checkUri);
        assert(isUrl(redirectUri), "redirectUri is not url: " + checkUri);

        console.log("TicketComponent, redirectUri:", redirectUri, ", sysId:", sysId, ", type:", type);

        let promise =  new Api(this.props.rootState).get(Api.Action.AUTH_CHECK_TICKET, {
            sys_id: sysId,
            type: type,
            code: code,
            check_uri: checkUri,
            redirect_uri: redirectUri,
        });

        this.whenGetResp(promise, sysId, checkUri, redirectUri);
    }

    checkTokenQQ() {
        let urlParams = new URLSearchParams(window.location.search);
        let sysId = getUrlParams(urlParams, 'sys_id');
        let type = getUrlParams(urlParams, 'type');
        let code = getUrlParams(urlParams, 'code');
        let checkUri = getUrlFromUrlParams(urlParams, 'check_uri');
        let redirectUri = getUrlFromUrlParams(urlParams, 'redirect_uri');

        console.log("TicketComponent, redirectUri:", redirectUri, ", sysId:", sysId, ", type:", type);

        let promise =  new Api(this.props.rootState).get(Api.Action.AUTH_CHECK_TICKET, {
            sys_id: sysId,
            type: type,
            code: code,
            check_uri: checkUri,
            redirect_uri: redirectUri,
        });

        this.whenGetResp(promise, sysId, checkUri, redirectUri);
    }

    checkTokenWechatWork() {
        let urlParams = new URLSearchParams(window.location.search);
        let sysId = getUrlParams(urlParams, 'sys_id');
        let type = getUrlParams(urlParams, 'type');
        let code = getUrlParams(urlParams, 'code');
        let checkUri = getUrlFromUrlParams(urlParams, 'check_uri');
        let redirectUri = getUrlFromUrlParams(urlParams, 'redirect_uri');

        console.log("TicketComponent, redirectUri:", redirectUri, ", sysId:", sysId, ", type:", type);

        let promise =  new Api(this.props.rootState).get(Api.Action.AUTH_CHECK_TICKET, {
            sys_id: sysId,
            type: type,
            code: code,
            check_uri: checkUri,
            redirect_uri: redirectUri,
        });

        this.whenGetResp(promise, sysId, checkUri, redirectUri);
    }

    checkTokenWeTest() {
        let urlParams = new URLSearchParams(window.location.search);
        let sysId = getUrlParams(urlParams, 'sys_id');
        let type = getUrlParams(urlParams, 'type');
        let code = getUrlParams(urlParams, 'code');
        let checkUri = getUrlFromUrlParams(urlParams, 'check_uri');
        let redirectUri = getUrlFromUrlParams(urlParams, 'redirect_uri');

        console.log("TicketComponent, redirectUri:", redirectUri, ", sysId:", sysId, ", type:", type);

        let promise =  new Api(this.props.rootState).get(Api.Action.AUTH_CHECK_TICKET, {
            sys_id: sysId,
            type: type,
            code: code,
            check_uri: checkUri,
            redirect_uri: redirectUri,
        });

        this.whenGetResp(promise, sysId, checkUri, redirectUri);
    }

    async fetchOaIp(callback)
    {
        try {
            const response = await fetch(process.env.REACT_APP_TPS_OA_IP_URL);
            response.text().then(function (text) {
                callback(text);
            }).catch(function (e) {
                alert("不能获取主机IP, 请联系 TPS 成员处理");
            });
        } catch (e) {
            alert("不能获取主机IP, 请联系 TPS 成员处理");
        }
    }

    getOaIpCallback(ip) {
        console.log("getOaIpCallback, ip", ip);
        if (!ip || (ip.indexOf("10.") === -1 && ip.indexOf("9.") === -1)) {
            message.showError("没有获取到主机IP, 而这是身份验证所必须的... 请联系 「腾讯公共服务」 成员协助解决<br/>所有的IP地址如下: " + ip);
        }
        $("#ip_address").text(ip);

        let urlParams = new URLSearchParams(window.location.search);
        let ticket = getUrlParams(urlParams, 'ticket');
        let sysId = getUrlParams(urlParams, 'sys_id');
        let type = getUrlParams(urlParams, 'type');
        let checkUri = getUrlFromUrlParams(urlParams, 'check_uri');
        let redirectUri = getUrlFromUrlParams(urlParams, 'redirect_uri');

        console.log("TicketComponent, redirectUri:", redirectUri, ", ticket:", ticket, ", sysId:", sysId, ", type:", type);

        let promise =  new Api(this.props.rootState).get(Api.Action.AUTH_CHECK_TICKET, {
            ip: ip,
            ticket: ticket,
            sys_id: sysId,
            type: type,
            check_uri: checkUri,
            redirect_uri: redirectUri,
        });

        this.whenGetResp(promise, sysId, checkUri, redirectUri);
    }

    render() {
        return (
            <div className='Ticket'>
                <Grid className="Ticket-grid">
                    <Row>
                        <Cell columns={2}/>

                        <Cell columns={8}>
                            <h1>正在验证票据, 请稍候 ...</h1>

                            <p>我们正在「 验证 」登录票据, 这个过程一般会在 15 秒内完成.</p>
                            <p>IP: <span id="ip_address">正在查询中 ...</span></p>
                        </Cell>

                        <Cell columns={2}/>
                    </Row>
                </Grid>
            </div>
        )
    }
}

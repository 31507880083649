import { connect } from 'react-redux'

import {mergeState} from '../../actions/foundation';
import { LayoutWithRouteComponent } from "../../components/foundation/LayoutWithRoute";

const mapStateToProps = state => {
    return {
        ...state.foundation,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        storeState: (state) => {
            dispatch(mergeState(state))
        },
    }
};

export const LayoutWithRoute = connect(
    mapStateToProps,
    mapDispatchToProps
)(LayoutWithRouteComponent);

export default LayoutWithRoute;

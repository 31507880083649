import {storeDispatch} from "../reducers/utils";
import {showLoading, hideLoading} from "../actions/foundation";


function _showLoading() {
    setTimeout(() => {
        storeDispatch(showLoading());
    }, 1);
}

function _hideLoading() {
    setTimeout(() => {
        storeDispatch(hideLoading());
    }, 1);
}

export const Loading = {
    show: _showLoading,
    hide: _hideLoading,
};
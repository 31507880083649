import {mergeState} from '../utils'

export const foundation = (state, rootState, action) => {
    switch (action.type) {
        case 'FOUNDATION_MERGE_STATE':
            return mergeState(state, action.stateToMerge);
        case 'FOUNDATION_SHOW_LOADING':
            return mergeState(state, {loading: true});
        case 'FOUNDATION_HIDE_LOADING':
            return mergeState(state, {loading: false});
        default:
            return state
    }
};

export default foundation
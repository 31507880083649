import React, {Component} from "react";
import {SnackBar} from './Snackbar'

export class LayoutTinyComponent extends Component {
    render() {
        return (
            <div className="LayoutTiny">
                { this.props.children }
                <SnackBar/>
            </div>
        );
    }
}

export default LayoutTinyComponent;

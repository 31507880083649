export const mergeState = (stateToMerge) => ({
    type: 'PERMISSION_MERGE_STATE',
    stateToMerge
});

export const mergeSelectGroupState = (stateToMerge) => ({
    type: 'PERMISSION_MERGE_SELECT_GROUP_STATE',
    stateToMerge
});

export const fetchGroups = () => ({
    type: 'PERMISSION_FETCH_GROUPS'
});

export const changeGroup = (groupId) => ({
    type: 'PERMISSION_CHANGE_GROUP',
    groupId
});

export const deleteGroup = (groupId) => ({
    type: 'PERMISSION_DELETE_GROUP',
    groupId
});

export const fetchTargets = () => ({
    type: 'PERMISSION_FETCH_TARGETS'
});

export const deleteTarget = (targetId) => ({
    type: 'PERMISSION_DELETE_TARGET',
    targetId
});

export const addGroup = (groupInfo) => ({
    type: 'PERMISSION_ADD_GROUP',
    groupInfo
});

export const addAccess = (accessInfo) => ({
    type: 'PERMISSION_ADD_ACCESS',
    accessInfo
});

export const deleteOwner = (groupId, ownerId) => ({
    type: 'PERMISSION_DELETE_OWNER',
    groupId,
    ownerId
});

export const deleteMember = (groupId, memberId) => ({
    type: 'PERMISSION_DELETE_MEMBER',
    groupId,
    memberId
});

export const changeGroupAccess = (groupId, targetId, level) => ({
    type: 'PERMISSION_CHANGE_GROUP_ACCESS',
    groupId,
    targetId,
    level
});

export const createGroupLink = (groupId, roleType) => ({
    type: 'PERMISSION_CREATE_ADD_GROUP_LINK',
    groupId,
    roleType,
});
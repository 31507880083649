import React from 'react';
import {LayoutContext} from '../foundation/Layout'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export class GroupListComponent extends React.Component {

    constructor(props) {
        super(props);

        this.onGroupSelect = this.onGroupSelect.bind(this);
        this.onGroupDelete = this.onGroupDelete.bind(this);
        this.state = {
            isOpen: false,
            groupId: 0,
        };
    }

    componentDidMount() {
        this.title.focus();
        this.props.fetchGroups();
    }

    handleClose = () => {
        this.setState({ isOpen: false });
    };

    handleDelete = () => {
        this.props.deleteGroup(this.state.groupId);

        this.setState({
            isOpen: false,
            groupId: 0,
        });
    };

    render() {
        console.log("GroupListComponent, render props", this.props);

        let {selectedGroup, groups} = this.props;
        selectedGroup = selectedGroup ? selectedGroup: {};
        return (
            <React.Fragment>
                <LayoutContext.Consumer>
                    {
                        layoutContextObj => {
                            this.setRedirect = layoutContextObj.setRedirect
                        }
                    }
                </LayoutContext.Consumer>
                <div className="GroupList">
                    <Dialog
                        open={this.state.isOpen}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"提示"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                确定删除该用户组？
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleDelete} color="primary">
                                确定
                            </Button>
                            <Button onClick={this.handleClose} color="primary" autoFocus>
                                取消
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <h2 ref={(title) => { this.title = title; }}> 用户组列表 </h2>
                    <ul className="mdc-list mdc-list--two-line">
                        {
                            groups && groups.map((group, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={"GroupList-item mdc-list-item " + (selectedGroup.groupId === group.group_id ? "mdc-list-item--activated" : "")}
                                        onClick={this.onGroupSelect}
                                        >
                                        <b>{index + 1}</b>
                                        <span className="mdc-list-item__text GroupList-group-item" style={{marginLeft: "20px"}}>
                                            <span className="GroupList-group mdc-list-item__primary-text"
                                                  data-group-id={group.group_id}
                                                  data-group-name={group.group_name}>
                                            {group.group_name}
                                            </span>
                                            <span className="mdc-list-item__secondary-text">id: {group.group_id}</span>
                                        </span>
                                        <div className="DeleteGroup-div" onClick={(e) => this.onGroupDelete(group.group_id, e)}><b>x</b></div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>

            </React.Fragment>
        );
    }

    onGroupSelect(e) {
        e.preventDefault();
        let elem = e.currentTarget.querySelector(".GroupList-group");
        this.props.changeGroup(parseInt(elem.getAttribute("data-group-id"), 10));
    }

    onGroupDelete(groupId, e) {
        // 禁止事件继续冒泡
        e.stopPropagation();
        this.setState({
            isOpen: true,
            groupId: groupId,
        });
    }
}

export default GroupListComponent;
import {connect} from "react-redux";
import { GroupDetailComponent } from '../../components/permission/GroupDetail'
import {
    deleteOwner,
    deleteMember,
    changeGroupAccess,
    createGroupLink,
    mergeState,
    mergeSelectGroupState,
    changeGroup
} from '../../actions/permission';
const mapStateToProps = state => {
    return {
        ...state.permission.selectedGroup,
        accessModalIsOpen: state.permission.accessModalIsOpen,

    }
};

const mapDispatchToProps = dispatch => {

    return{
        storeState: (state) => {
            dispatch(mergeState(state))
        },
        storeSelectGroupState: (state) => {
            dispatch(mergeSelectGroupState(state))
        },
        deleteOwner: (groupId, ownerId) => {
            dispatch(deleteOwner(groupId, ownerId))
        },
        deleteMember: (groupId, memberId) => {
            dispatch(deleteMember(groupId, memberId))
        },
        changeGroupAccess: (groupId, targetId, level) => {
            dispatch(changeGroupAccess(groupId, targetId, level))
        },
        createGroupLink: (groupId, roleType) => {
            dispatch(createGroupLink(groupId, roleType))
        },
        changeGroup: (groupId) => {
            dispatch(changeGroup(groupId))
        },
    }
};



export const GroupDetail = connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupDetailComponent);

export default GroupDetail;
export const mergeState = (stateToMerge) => ({
    type: 'FOUNDATION_MERGE_STATE',
    stateToMerge
});

export const showLoading = () => ({
    type: 'FOUNDATION_SHOW_LOADING',
});


export const hideLoading = () => ({
    type: 'FOUNDATION_HIDE_LOADING',
});

import React from "react";
import withRipple from '@material/react-ripple';

export const AthenaSpan = (props) => {
    const {
        children,
        rippleActivator,
        ...otherProps
    } = props;

    return (
        <span ref={rippleActivator} {...otherProps} > {children} </span>
    );
};

export class AthenaSpanComponent extends React.Component {
    rippleActivator = React.createRef();

    init = (el) => {
        this.props.initRipple(el /* surface */, this.rippleActivator.current /* activator */);
    };

    render() {
        const {
            className,
            initRipple,
            unbounded,
            children,
            ...otherProps
        } = this.props;

        delete otherProps.hasRipple;

        return (
            <div
                className={`AthenaSpanComponent ${className}`}
                ref={this.init}
                {...otherProps}>
                <AthenaSpan rippleActivator={this.rippleActivator} children={children}/>
            </div>
        );
    }
}

export const RippleSpan = withRipple(AthenaSpanComponent);
export default RippleSpan;

import React from 'react';
import {LayoutContext} from "../foundation/Layout";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";

export class TargetListComponent extends React.Component {

    constructor(props) {
        super(props);

        this.onTargetSelect = this.onTargetSelect.bind(this);
        this.onTargetDelete = this.onTargetDelete.bind(this);
        this.state = {
            isOpen: false,
            targetId: "",
        };
    }

    componentDidMount() {
        this.title.focus();
        this.props.fetchTargets();
    }

    handleClose = () => {
        this.setState({ isOpen: false });
    };

    handleDelete = () => {
        this.props.deleteTarget(this.state.targetId);

        this.setState({
            isOpen: false,
            targetId: "",
        });
    };

    render() {
        console.log("TargetListComponent, render props", this.props);

        let {selectedTarget, targets} = this.props;
        selectedTarget = selectedTarget ? selectedTarget: {};
        return (
            <React.Fragment>
                <LayoutContext.Consumer>
                    {
                        layoutContextObj => {
                            this.setRedirect = layoutContextObj.setRedirect
                        }
                    }
                </LayoutContext.Consumer>
                <div className="TargetList">
                    <Dialog
                        open={this.state.isOpen}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"提示"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                确定删除该资源？
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleDelete} color="primary">
                                确定
                            </Button>
                            <Button onClick={this.handleClose} color="primary" autoFocus>
                                取消
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <h2 ref={(title) => { this.title = title; }}> 资源列表 </h2>
                    <ul className="mdc-list mdc-list--two-line">
                        {
                            targets && targets.map((target, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={"TargetList-item mdc-list-item " + (selectedTarget === target.target_id ? "mdc-list-item--activated" : "")}
                                        onClick={this.onTargetSelect}
                                    >
                                        <b>{index + 1}</b>
                                        <span className="mdc-list-item__text TargetList-group-item" style={{marginLeft: "20px"}}>
                                            <span className="TargetList-target mdc-list-item__primary-text"
                                                  data-target-id={target.target_id}>
                                            {target.target_id}
                                            </span>
                                            <span className="mdc-list-item__secondary-text">id: {target.target_id}</span>
                                        </span>
                                        <div className="DeleteTarget-div" onClick={(e) => this.onTargetDelete(target.target_id, e)}><b>x</b></div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>

            </React.Fragment>
        );
    }

    onTargetSelect(e) {
        e.preventDefault();
        let elem = e.currentTarget.querySelector(".TargetList-target");
        this.props.storeState({
            selectedTarget: elem.getAttribute("data-target-id"),
        });
    }

    onTargetDelete(targetId, e) {
        // 禁止事件继续冒泡
        e.stopPropagation();
        this.setState({
            isOpen: true,
            targetId: targetId,
        });
    }
}

export default TargetListComponent;